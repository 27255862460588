<template>
  <div v-if="orders.length" class="my-orders m-5 p-3">
    <div class="p-2 has-text-weight-bold">
    {{ $t('orders.orderListTitle') }}
    </div>
    <div class="orderList">
      <template v-for="order in orders">
        <div class="orderItem p-2" :key="order.id">
          <div>{{ order.orderId }}</div>
          <b-tag 
            class="orderStatusTag"
            :type="OrderTagType(order.status)">
            {{ $t('orders.status_'+ order.status) }}
          </b-tag>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import axios from "axios";

export default {
  props: {},
  data() {
    return {
      orders: [],
    }
  },
  methods:{
    OrderTagType(status){
      switch(status){
        case "processed":
          return "is-success";
        case "delivered":
          return "is-success";
        default:
          return "is-info";
      }
    },
    async FetchOrders(){
      const resOrders = await axios.get(process.env.VUE_APP_API_BASE + `/api/orders/?where[user][equals]=${this.$store.state.user.profile.id}`);
      if(resOrders.status == 200){
        this.orders = resOrders.data.docs;
        console.log("User Orders", this.orders)
        if(this.orders.some(x => x.status == "processed")){
          // Show notification
          this.$buefy.notification.open({
            message: this.$t('orders.orderReadyNotification'),
            type: "is-success"
          })
        }
      }else{
        console.warn("Error al cargar las fotos", resOrders)
      }
    }
  },
  sockets:{
    userOrderChanged(data){
      console.log("User Order Changed", data)
      // Update order status
      const order = this.orders.find(order => order.id == data.id)
      if(!order){
        console.log("Orden no encontrada. Fetcheo todo")
        this.FetchOrders();
      }else{
        console.log("Estado de orden actualizado")
        order.status = data.status;
      }
    }
  },
  async mounted(){
    this.FetchOrders();
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/variables.scss';

.my-orders {
  background: $secundary;
}

.orderItem {
  display: flex;
  justify-content: space-between;

  &:not(:last-child) {
    border-bottom: 1px solid #f3e5dc;
  }
}

.orderStatusTag{
  text-transform: capitalize;
}

</style>